import Vue from 'vue'
import * as echarts from "echarts";
import { EleResize } from "@/utils/esresize";
import clone from "clone";
import { histogramOption, contrastDSJZOption } from "../charts/bar/bar"
import { baseLineOption, lineHistogramOption, linkageShareOption } from "../charts/line/line"
import { basePieOption } from "../charts/pie/pie"
import {
  DEFAULT_COLOR_CASE,
  DEFAULT_FUNCTION_CFG,
  DEFAULT_LABEL,
  DEFAULT_LEGEND_STYLE,
  DEFAULT_SIZE,
  DEFAULT_SPLIT,
  DEFAULT_THRESHOLD,
  DEFAULT_TITLE_STYLE,
  DEFAULT_TOOLTIP,
  DEFAULT_TOTAL,
  DEFAULT_XAXIS_STYLE,
  DEFAULT_YAXIS_EXT_STYLE,
  DEFAULT_YAXIS_STYLE,
  BASE_BAR,
  BASE_LINE,
  BASE_LINEBAR,
  BASE_PIE,
  BASE_LINESHARE
} from '../charts/chart'
export class chartView {

  constructor() {
    this.optionInformation = {
      id: null,
      chartsId: "",
      chartsType: "",
      coordinateX: 0,
      coordinateY: 0,
      width: 400,
      height: 300,
      title: "",
      options: {},
      chartSet: {},
      customAttr: {
        color: DEFAULT_COLOR_CASE,
        size: DEFAULT_SIZE,
        label: DEFAULT_LABEL,
        tooltip: DEFAULT_TOOLTIP,
        totalCfg: DEFAULT_TOTAL
      },
      customStyle: {
        text: DEFAULT_TITLE_STYLE,
        legend: DEFAULT_LEGEND_STYLE,
        xAxis: DEFAULT_XAXIS_STYLE,
        yAxis: DEFAULT_YAXIS_STYLE,
        yAxisExt: DEFAULT_YAXIS_EXT_STYLE,
        split: DEFAULT_SPLIT
      },
      senior: {
        functionCfg: DEFAULT_FUNCTION_CFG,
        assistLine: [],
        threshold: DEFAULT_THRESHOLD
      },
    },
      this.$nextTick = Vue.prototype.$nextTick

    this.addChars = this.addChars
    this.initOptions = this.initOptions
    this.chartChturs = []
    this.removeCharts = this.removeCharts
    this.isgetBase64 = this.isgetBase64
  }
  // 获取详情 显示已有的echarts图
  initOptions(newres, container, makePicture) {
    this.chartChturs = container
    // let option = clone(this.optionInformation);

    let option = { ...this.optionInformation, ...newres, ...newres.webConfig, }
    option.data = newres.data
    option.options = null
    // this.optionInformation = option
    option.chartSet.title = option.title;
    option.options = this.optionsComputed(
      option.chartsType,
      option
    );
    if (container) {
      let hasItem = false
      let hasIndex = null
      container.map((item, index) => {
        {
          if (item.chartsId == option.chartsId) {
            item = option
            hasItem = true
            hasIndex = index
          }
        }
      })
      if (!hasItem) {

        container.push(option)
      } else {
        this.removeCharts(option.chartsId)

        container.splice(hasIndex, 1, option)
      }
      this.chartChturs = container
    }
    return new Promise((resolve, roject) => {
      this.$nextTick(async () => {
        const curentChart = await this.addChars(
          option.chartsId,
          option.options,
          newres.chartsType,
        );
        if (makePicture) {
          option.DataURL = curentChart.getDataURL({
            pixelRatio: 2,
            type: "png",
            backgroundColor: "#fff",
          });
        }
        resolve(option)
      });
    })

  }
  // 渲染Ydrag

  // 计算 option

  optionsComputed = (type, charts) => (
   {
    'charDSJZ': () => contrastDSJZOption(JSON.parse(JSON.stringify(BASE_BAR)), charts),
    'histogram': () => histogramOption(JSON.parse(JSON.stringify(BASE_BAR)), charts),
    'contrastDSJZ': () => contrastDSJZOption(JSON.parse(JSON.stringify(BASE_BAR)), charts),
    "lineChart": () => baseLineOption(JSON.parse(JSON.stringify(BASE_LINE)), charts),
    'lineHistogram': () => lineHistogramOption(JSON.parse(JSON.stringify(BASE_LINEBAR)), charts),
    "charbB": () => basePieOption(JSON.parse(JSON.stringify(BASE_PIE)), charts),
    "linkageShare": () => linkageShareOption(JSON.parse(JSON.stringify(BASE_LINESHARE)), charts)
  })[type]()




  // 添加到echarts
  addChars(boxId, boxOption, type) {
    var chartDom = document.getElementById(boxId);
    var myChart = echarts.init(chartDom);
    var option = boxOption;
    return new Promise((resolve, roject) => {
      if (type == "linkageShare") {
        setTimeout(() => {
          myChart.on("updateAxisPointer", function (event) {
            const xAxisInfo = event.axesInfo[0];
            if (xAxisInfo) {
              const dimension = xAxisInfo.value + 1;
              myChart.setOption({
                series: {
                  id: "pie",
                  label: {
                    formatter: "{b}: {@[" + dimension + "]} ({d}%)",
                  },
                  encode: {
                    value: dimension,
                    tooltip: dimension,
                  },
                },
              });
            }
          });
          myChart.setOption(option);
    

        });
        let listener = function () {
          myChart.resize();
        };
        EleResize.on(chartDom, listener);
        setTimeout(() => {
          resolve(myChart)
  
         }, 1000);

      } else {
        myChart.hideLoading();
        myChart.setOption(option);
        let listener = function () {
          myChart.resize();
        };
        EleResize.on(chartDom, listener);
        // window.onresize = ()=>   EleResize.on(chartDom, listener);
       setTimeout(() => {
        resolve(myChart)
       }, 1000);


      }
    })
  }
  // 删除方法 
  removeCharts(boxId) {
    let chartDom = document.getElementById(boxId);

    let myChart = echarts.init(chartDom);
    myChart.clear()

  }

}