// 对比图 source
export const BASE_DSJZ_SOURCE = [
    {
        product: "Matcha Latte",
        2015: 43.3,
        2016: 85.8,
        2017: 93.7,
    },
    {
        product: "Milk Tea",
        2015: 83.1,
        2016: 73.4,
        2017: 55.1,
    },
    {
        product: "Cheese Cocoa",
        2015: 86.4,
        2016: 65.2,
        2017: 82.5,
    },
    {
        product: "Walnut Brownie",
        2015: 72.4,
        2016: 53.9,
        2017: 39.1,
    },
]
export const BASE_DSJZ_SOURCE_EMPTY =  [{ product: "暂无数据", 0: 0 }]
// 对比图 diemensions
export const BASE_DSJZ_DIEMENSIONS = ["product", "2015", "2016", "2017"]
export const  BASE_DSJZ_DIEMENSIONS_EMPTY= ["product", "0", "0", "0"]

// 普通柱状图的横轴默认数据 diemensions

export const BASE_BAROPTION_NAMEDATA = ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"];
export const BASE_BAROPTION_NAMEDATA_EMPTY = ['无数据']
// 普通柱状图的横轴默认value

export const BASE_BAROPTION_VALUEDATA = [5, 20, 36, 10, 10, 20]
export const BASE_BAROPTION_VALUEDATA_EMPTY = ['无数据']

export const BASE_LINEOPTION_NAMEDATA = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
export const BASE_LINEOPTION_NAMEDATA_EMPTY=["无数据"]

export const BASE_LINEPTION_VALUEDATA = [150, 230, 224, 218, 135, 147, 260]
export const BASE_LINEPTION_VALUEDATA_EMPTY = ['无数据']

export const BASE_PIE_DATALIST = [{ value: 1048, name: "Search Engine" },
{ value: 735, name: "Direct" },
{ value: 580, name: "Email" },
{ value: 484, name: "Union Ads" },
{ value: 300, name: "Video Ads" },]

export const BASE_PIE_DATALIST_EMPTY=[{value:0,name:'暂无数据'}]


export const BASE_LINEHISTORGRAM_SERIS = [{
    name: "Evaporation",
    type: "bar",
    data: [
        2.0,
        4.9,
        7.0,
        23.2,
        25.6,
        76.7,
        135.6,
        162.2,
        32.6,
        20.0,
        6.4,
        3.3,
    ],
},
{
    name: "Precipitation",
    type: "bar",
    data: [
        2.6,
        5.9,
        9.0,
        26.4,
        28.7,
        70.7,
        175.6,
        182.2,
        48.7,
        18.8,
        6.0,
        2.3,
    ],
},
{
    name: "Temperature",
    type: "line",
    yAxisIndex: 1,
    data: [
        2.0,
        2.2,
        3.3,
        4.5,
        6.3,
        10.2,
        20.3,
        23.4,
        23.0,
        16.5,
        12.0,
        6.2,
    ],
},]
export const BASE_LINEHISTORGRAM_SERIS_EMPTY =[
    { name: "Evaporation", type: "bar", data: [0] },
    { name: "Precipitation", type: "bar", data: [0] },
    { name: "Temperature", type: "line", yAxisIndex: 1, data: [0] },
  ];


export const BASE_LINESHARE_SOURCE =   [ [
    "product",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
  ],
  ["Milk Tea", 56.5, 82.1, 88.7, 70.1, 53.4, 85.1],
  ["Matcha Latte", 51.1, 51.4, 55.1, 53.3, 73.8, 68.7],
  ["Cheese Cocoa", 40.1, 62.2, 69.5, 36.4, 45.2, 32.5],
  ["Walnut Brownie", 25.2, 37.1, 41.2, 18, 33.9, 49.1]]

  export const BASE_LINESHARE_SOURCE_EMPTY=[["product", "暂无数据"], ["暂无数据", 0]]

  export const colorList=[
      '#5470C6', '#91CC75', '#FFDC60', '#EE6666', '#73C0DE', '#3BA272',
      '#FC8452', '#9A60B4', '#EA7CCC', '#225b15', '#7f47d5', '#6757b8',
      '#8d288a', '#79313a', '#b0003d', '#163d29', '#92a315', '#da0fa9',
      '#81d405', '#2cc370', '#44c01d', '#92155d', '#f47c1f', '#dc61ea',
      '#7d990e', '#6669af', '#b55f22', '#4a1a4c', '#c5be07', '#3cd25b',
      '#e94fcd', '#8a58d2', '#49d676', '#edc5b1', '#e9ab81', '#fb9053',
      '#344636', '#bc6e1e', '#664579', '#54fea0', '#e140c8', '#21e69f',
      '#7171b4', '#f1bc5c', '#759461', '#9a020b', '#dab66d', '#8e2d87',
      '#39bd64', '#e156d5', '#57a302', '#eb4929', '#3a451',  '#2e536b',
      '#bdaf02', '#78355',  '#29863c', '#a7003a', '#7171a3', '#7fa092',
      '#acf410', '#da8349', '#29b498', '#e4382d', '#3876a',  '#6e4313',
      '#dd9842', '#f769f3', '#542346', '#46e6e3', '#91ba44', '#775ebc',
      '#29120f', '#7aa653', '#cd24f0', '#75b34b', '#9a1241', '#ee16a9',
      '#558de7', '#e59ce9', '#2a407b', '#ad1036', '#34fe75', '#a2302b',
      '#8f8717', '#fab346', '#2c142c', '#eac2b7', '#3fcb37', '#3e7b3b',
      '#f29b48', '#e7379',  '#81bbcc', '#e4cd49', '#6224c',  '#4d01e7',
      '#65c31b', '#2cc38f', '#682c7f', '#5d4e3b'
    ]

