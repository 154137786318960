
import { BASE_ECHARTS_SELECT, DEFAULT_TOOLTIP, } from '../chart'
import {BASE_PIE_DATALIST,colorList} from "../baseData"
import clone from "clone"

export function basePieOption(chart_option, chart) {
    // 处理shape attr
    chart.type = 'pie'
  chart_option.title.text =  chart.chartSet.title || chart.title

    let customAttr = {}
    if (chart.customAttr) {
            chart.customAttr
      if (customAttr.color) {
        chart_option.color = customAttr.color.colors
      }
      // tooltip
      if (customAttr.tooltip) {
        const tooltip = JSON.parse(JSON.stringify(customAttr.tooltip))
        const reg = new RegExp('\n', 'g')
        tooltip.formatter = tooltip.formatter.replace(reg, '<br/>')
        chart_option.tooltip = tooltip
  
        const bgColor = tooltip.backgroundColor ? tooltip.backgroundColor : DEFAULT_TOOLTIP.backgroundColor
        chart_option.tooltip.backgroundColor = bgColor
        chart_option.tooltip.borderColor = bgColor
      }
    }
    // 处理data
    
        let data = chart.data
        let dataList = [];
      if (data && data?.length) {
        for (let i = 0; i < data.length; i++) {
          let newdata = {
            value: data[i].value,
            name: data[i].name, 
            itemStyle:{color:colorList[i]}
          };
          dataList.push(newdata);
        }
      } else {
        dataList =[{ value: 0, name: "暂无数据" }];
      }

      chart_option .series.push( {
        name: "数据来源:" +  chart.chartSet.title || chart.title,
        type: "pie",
        radius: "80%",
        center: ["40%", "50%"],
        data: dataList,
        itemStyle: {
            normal: {
              color: function (colors) {
               
                return colorList[colors.dataIndex];
              }
            },
          },
        

      },)
 
    // componentStyle(chart_option, chart)

    return chart_option
  }
  
  export function rosePieOption(chart_option, chart) {
    // 处理shape attr
    let customAttr = {}
    if (chart.customAttr) {
      customAttr = JSON.parse(chart.customAttr)
      if (customAttr.color) {
        chart_option.color = customAttr.color.colors
      }
      // tooltip
      if (customAttr.tooltip) {
        const tooltip = JSON.parse(JSON.stringify(customAttr.tooltip))
        const reg = new RegExp('\n', 'g')
        tooltip.formatter = tooltip.formatter.replace(reg, '<br/>')
        chart_option.tooltip = tooltip
  
        const bgColor = tooltip.backgroundColor ? tooltip.backgroundColor : DEFAULT_TOOLTIP.backgroundColor
        chart_option.tooltip.backgroundColor = bgColor
        chart_option.tooltip.borderColor = bgColor
      }
    }
    // 处理data
    if (chart.data) {
      chart_option.title.text = chart.title
      if (chart.data.series.length > 0) {
        chart_option.series[0].name = chart.data.series[0].name
        chart_option.series[0].selectedMode = true
        chart_option.series[0].select = BASE_ECHARTS_SELECT
        // size
        if (customAttr.size) {
          chart_option.series[0].radius = [customAttr.size.pieInnerRadius + '%', customAttr.size.pieOuterRadius + '%']
          chart_option.series[0].roseType = customAttr.size.pieRoseType
        }
        // label
        if (customAttr.label) {
          chart_option.series[0].label = customAttr.label
        }
        const valueArr = chart.data.series[0].data
        for (let i = 0; i < valueArr.length; i++) {
          const y = valueArr[i]
          y.name = chart.data.x[i]
          // color
          y.itemStyle = {
            color: hexColorToRGBA(customAttr.color.colors[i % customAttr.color.colors.length], customAttr.color.alpha),
            borderRadius: customAttr.size.pieRoseRadius
          }
          y.type = 'pie'
          chart_option.series[0].data.push(y)
        }
      }
    }
    // componentStyle(chart_option, chart)
    return chart_option
  }
  