import { BASE_ECHARTS_SELECT, DEFAULT_TOOLTIP, } from '../chart'
import { axisLabel,yAxisLabel } from '../common'
import * as baseData from "../baseData" 

// BASE_DSJZ_SOURCE, BASE_DSJZ_DIEMENSIONS
import clone from "clone"

export function baseBarOption(chart_option, chart, activeParam) {
  chart.type = 'bar'
  // 处理shape attr
  chart_option.title.text =  chart.chartSet.title || chart.title

  let customAttr = {}
  if (chart.customAttr) {
    customAttr = chart.customAttr || JSON.parse(chart.customAttr)
    if (customAttr.color) {
      chart_option.color = customAttr.color.colors
    }
    // tooltip
    if (customAttr.tooltip) {
      const tooltip = JSON.parse(JSON.stringify(customAttr.tooltip))
      const reg = new RegExp('\n', 'g')
      tooltip.formatter = tooltip.formatter.replace(reg, '<br/>')
      chart_option.tooltip = tooltip

      const bgColor = tooltip.backgroundColor ? tooltip.backgroundColor : DEFAULT_TOOLTIP.backgroundColor
      chart_option.tooltip.backgroundColor = bgColor
      chart_option.tooltip.borderColor = bgColor
    }
  }
  chart_option.xAxis.axisLabel = axisLabel


  chart_option.yAxis.axisLabel = yAxisLabel

  // 处理样式的方法 目前还未启用
  // componentStyle(chart_option, chart)
  // seniorCfg(chart_option, chart)
  return chart_option
}


export function histogramOption(chart_option, charts) {

  let data1 = [];
  let data2 = [];
  chart_option = baseBarOption(chart_option, charts)
  //  let chart = {...charts}


  if (charts && charts?.data?.length) {
    for (let i = 0; i < charts.data.length; i++) {
      data1.push(charts.data[i].name);
      data2.push(charts.data[i].value);
    }
  } else {
    data1 = charts.viewType=='view'? baseData.BASE_BAROPTION_NAMEDATA_EMPTY : baseData.BASE_BAROPTION_NAMEDATA;
    data2 =charts.viewType=='view'? [0]: baseData.BASE_BAROPTION_VALUEDATA;
  }
  chart_option.xAxis.data = data1
  chart_option.series.push({ type: 'bar', data: data2 })

  return chart_option
}

// 处理对比图的业务逻辑
export function contrastDSJZOption(chart_option, charts) {
  chart_option = baseBarOption(chart_option, charts)
  let newdata = [];
  let typedata = [];
  
  if (charts.data &&charts.data.dimensions) {
    newdata = clone(charts.data.dimensions);
    for (let i = 0; i < newdata.length - 1; i++) {
      let data = {
        type: "bar",
      };
      typedata.push(data);
    }
  } else {
    typedata = [{ type: "bar" }, { type: "bar" }, { type: "bar" }];
  }
  chart_option.dataset = {}
  chart_option.dataset.dimensions = charts?.data?.dimensions ? charts.data.dimensions : (charts.viewType=='view'?baseData. BASE_DSJZ_DIEMENSIONS_EMPTY:baseData.BASE_DSJZ_DIEMENSIONS)
  chart_option.dataset.source = charts?.data?.source ? charts.data.source :(charts.viewType=='view' ?  baseData.BASE_DSJZ_SOURCE_EMPTY:baseData. BASE_DSJZ_SOURCE)
  chart_option.xAxis.type = "category"
  chart_option.series = typedata
  delete chart_option.xAxis.data

  return chart_option
}