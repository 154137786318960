import { BASE_ECHARTS_SELECT, DEFAULT_TOOLTIP, } from '../chart'
import { componentStyle, yAxisLabel, axisLabel } from '../common'
import clone from "clone"

import * as baseData from "../baseData"
export function baseLineOption(chart_option, chart) {

  chart.type = 'line'
  chart_option.title.text = chart.chartSet.title || chart.title
  // 处理shape attr
  let customAttr = {}
  if (chart.customAttr) {
    customAttr = chart.customAttr
    if (customAttr.color) {
      chart_option.color = customAttr.color.colors
    }
    // tooltip
    if (customAttr.tooltip) {
      const tooltip = JSON.parse(JSON.stringify(customAttr.tooltip))
      const reg = new RegExp('\n', 'g')
      tooltip.formatter = tooltip.formatter.replace(reg, '<br/>')
      chart_option.tooltip = tooltip

      const bgColor = tooltip.backgroundColor ? tooltip.backgroundColor : DEFAULT_TOOLTIP.backgroundColor
      chart_option.tooltip.backgroundColor = bgColor
      chart_option.tooltip.borderColor = bgColor
    }
  }
  // 处理data
  let data1 = [];
  let data2 = [];
  if (chart.data && chart.data?.length) {
    for (let i = 0; i < chart.data.length; i++) {
      data1.push(chart.data[i].name);
      data2.push(chart.data[i].value);
    }
  } else {
    data1 =  chart.viewType=='view' ? baseData.BASE_LINEOPTION_NAMEDATA_EMPTY : baseData.BASE_LINEOPTION_NAMEDATA;
    data2 =  chart.viewType=='view' ? baseData.BASE_LINEPTION_VALUEDATA_EMPTY : baseData.BASE_LINEPTION_VALUEDATA;
  }
  // if(chart.)
  // for (let i = 0; i < chart.data.series.length; i++) {
  //   const y = chart.data.series[i]
  //   // color
  //   y.itemStyle = {
  //     color: hexColorToRGBA(customAttr.color.colors[i % customAttr.color.colors.length], customAttr.color.alpha)
  //   }
  //   // size
  //   if (customAttr.size) {
  //     y.symbol = customAttr.size.lineSymbol
  //     y.symbolSize = customAttr.size.lineSymbolSize
  //     y.lineStyle = {
  //       width: customAttr.size.lineWidth,
  //       type: customAttr.size.lineType
  //     }
  //     y.smooth = customAttr.size.lineSmooth
  //     if (customAttr.size.lineArea) {
  //       y.areaStyle = {
  //         opacity: 0.6
  //       }
  //     } else {
  //       delete y.areaStyle
  //     }
  //   }
  //   y.selectedMode = true
  //   y.select = BASE_ECHARTS_SELECT
  //   // label
  //   if (customAttr.label) {
  //     y.label = customAttr.label
  //   }
  //   y.type = 'line'
  //   chart_option.legend.data.push(y.name)
  //   chart_option.series.push(y)
  // }
  // 默认是单条直线


  chart_option.xAxis.data = data1
  chart_option.series.push({
    data: data2,
    type: "line",
  },)
  chart_option.xAxis.axisLabel = axisLabel


  chart_option.yAxis.axisLabel = yAxisLabel

  //   componentStyle(chart_option, chart)
  //   seniorCfg(chart_option, chart)
  return chart_option
}

export function stackLineOption(chart_option, chart) {
  baseLineOption(chart_option, chart)

  // ext
  // chart_option.tooltip.trigger = 'axis'
  chart_option.series.forEach(function (s) {
    s.stack = 'stack'
  })
  return chart_option
}

export function lineHistogramOption(chart_option, chart) {

  chart_option.title.text = chart.chartSet.title || chart.title

  let data = chart.data

  let rows = [];
  if (data && data?.rows?.length) {
    rows = data.rows;
  } else {
    rows =chart.viewType=='view'? baseData.BASE_LINEOPTION_NAMEDATA_EMPTY :  baseData.BASE_LINEOPTION_NAMEDATA
  }
  let legend = [];
  let series = [];

  let yAxis = [];
  if (data && data?.cols?.length) {
    legend = data.cols.map((item) => item.name);
    yAxis = data.cols.map((item) => {
      let obj = {
        type: "value",
        name: item.name,
        axisLabel: yAxisLabel,
      };
      return obj;
    });
    data.cols.map((item,index) => {
      // item.yAxisIndex = index
      legend.push(item.name)
      yAxis.push(
        {
          type: "value",
          name: item.name,
          axisLabel: yAxisLabel,
        }
      )
    });
    series = data.cols;

  } else {
   
    legend = chart.viewType=='view'?baseData.BASE_LINEPTION_VALUEDATA :  baseData.BASE_LINEPTION_VALUEDATA_EMPTY;
    yAxis = [
      {
        type: "value",
        name: "Precipitation",
        min: 0,
        max: 250,
        interval: 50,
        axisLabel: {
          formatter: "{value} ml",
        },
      },
      {
        type: "value",
        name: "Temperature",
        min: 0,
        max: 25,
        interval: 5,
        axisLabel: {
          formatter: "{value} °C",
        },
      },
    ];
    series = chart.viewType=='view'?baseData.BASE_LINEHISTORGRAM_SERIS_EMPTY: baseData.BASE_LINEHISTORGRAM_SERIS

  }

  chart_option.legend.data = legend
  chart_option.yAxis = yAxis
  chart_option.series = series
  chart_option.xAxis.push({
    type: "category",
    data: rows,
    axisPointer: {
      type: "shadow",
    },
    axisLabel: axisLabel,
  },)

  return chart_option
}

export function linkageShareOption(chart_option, chart) {

  chart.type = 'line'
  chart_option.title.text = chart.chartSet.title || chart.title
  // 处理shape attr
  let customAttr = {}
  if (chart.customAttr) {
    customAttr = chart.customAttr
    if (customAttr.color) {
      chart_option.color = customAttr.color.colors
    }
    // tooltip
    if (customAttr.tooltip) {
      const tooltip = JSON.parse(JSON.stringify(customAttr.tooltip))
      const reg = new RegExp('\n', 'g')
      tooltip.formatter = tooltip.formatter.replace(reg, '<br/>')
      chart_option.tooltip = tooltip

      const bgColor = tooltip.backgroundColor ? tooltip.backgroundColor : DEFAULT_TOOLTIP.backgroundColor
      chart_option.tooltip.backgroundColor = bgColor
      chart_option.tooltip.borderColor = bgColor
    }
  }
  // 处理data
  let newdata = [];
  let data = chart.data
  if (data && data?.source?.length) {
    for (let i = 0; i < data.source.length - 1; i++) {
      let datalist = {
        type: "line",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: { focus: "series" },
      };
      newdata.push(datalist);
    }
    let linedata = {
      type: "pie",
      id: "pie",
      radius: "50%",
      center: ["45%", "25%"],
      emphasis: {
        focus: "self",
      },
      label: {
        formatter: "{b}: {@" + data.source[0][1] + "} ({d}%)",
      },
      encode: {
        itemName: data.source[0][0],
        value: data.source[0][1],
        tooltip: data.source[0][1],
      },
    };
    newdata.push(linedata);
  } else {
    newdata = [
      { type: "line", smooth: true, seriesLayoutBy: "row", emphasis: { focus: "series" } },
      { type: "line", smooth: true, seriesLayoutBy: "row", emphasis: { focus: "series" } },
      { type: "line", smooth: true, seriesLayoutBy: "row", emphasis: { focus: "series" } },
      { type: "line", smooth: true, seriesLayoutBy: "row", emphasis: { focus: "series" } },
      { type: "pie", id: "pie", radius: "50%", center: ["50%", "25%"], emphasis: { focus: "self" },
        label: { formatter: "{b}: {@2012} ({d}%)" },
        encode: { itemName: "product", value: "2012", tooltip: "2012" },
      },
    ];
  }
  chart_option.series = newdata
  chart_option.xAxis.axisLabel = axisLabel
  chart_option.yAxis.axisLabel = yAxisLabel
  chart_option.dataset.source = data?.source ||  [["product", "暂无数据"], ["暂无数据", 0]]
  return chart_option
}